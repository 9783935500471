/* globals AbstractPackagerService, Comparison, DataPackage, DateUtils, Enums, Granularities, Grid, Name */
(function () {
  'use strict';
  const SUMMARY_METRIC_FAMILY = 'Summary';

  const gridKeyFn = (granularity, activeGroup) => {
    if (_.isEmpty(granularity)) {
      return SUMMARY_METRIC_FAMILY;
    }
    if (_.has(granularity, 'node')) {
      return granularity.node;
    }
    if (_.has(granularity, activeGroup)) {
      return granularity[activeGroup];
    }
  };

  const pruneMetricFamily = (metricFamily, enabled, planType) => ({
    id: metricFamily.id,
    metrics: _.filter(metricFamily.metrics, (metric) => {
      // Remove Delta and Percent metrics if missInformation is disabled
      if (!enabled.missInformation && (metric.subType === 'delta' || metric.subType === 'missPercent')) {
        return false;
      }
      // Remove Cumulative metrics if cumulative toggle is disabled
      if (!enabled.cumulative && (metric.subType === 'cumulative')) {
        return false;
      }
      // Remove actuals for comparison plans or if actuals are disabled
      if ((!enabled.actuals || planType === 'comparison') && metric.type === 'Actual') {
        return false;
      }
      return true;
    })
  });

  const gridOrdering = (aggregates, enabled, activeGroup) => {
    const order = new Set(),
          nodes = (aggregates.node || []).sort(),
          aggregateGroup = _.map(_.sortBy(aggregates[activeGroup], ['displayRank', 'id']));

    aggregateGroup.forEach((group) => {
      order.add(group.name);
      // Display nodes directly underneath the group they are a part of
      if (group.id !== 'Amazon Network' && enabled.drillDown) {
        group.nodes.sort().forEach((node) => order.add(node));
      }
    });
    // Add the remaining nodes at the end if their group was not selected
    nodes.sort().forEach((node) => order.add(node));
    return [...order];
  };

  const addMetricCategoryDividers = (dataset, isSubsequentDataset, enabled) => {
    if (enabled.metricView) {
      return;
    }

    let category = _.head(dataset.records).metric.category;
    _.head(dataset.records).isStartOfMetricFamily = isSubsequentDataset;
    _.forEach(dataset.records, (record) => {
      if (!Comparison.areIdentityEqual(record.metric.category, category)) {
        record.isStartOfMetricCategory = true;
        category = record.metric.category;
      }
    });
  };

  const combineRecords = (currentValue, newValue, enabled) => {
    if (_.isNil(currentValue)) {
      // Do not add the metric family divider for the first metric family in the DataPackage
      addMetricCategoryDividers(newValue, false, enabled);
      return newValue;
    }
    // Add the metric family divider for every metric family except the first in the DataPackage
    addMetricCategoryDividers(newValue, true, enabled);
    currentValue.records = currentValue.records.concat(newValue.records);
    return currentValue;
  };

  const getGroupMappings = (filters, aggregates, aggregateGroup) => ({
    [aggregateGroup]: {
      granularityKey: 'node',
      mapping: filters[aggregateGroup].reduce((accumulator, group) => {
        accumulator[Name.ofIdentity(group)] = group.nodes || _.find(aggregates[aggregateGroup], { id: group.id }).nodes;
        return accumulator;
      }, {}) || {}
    }
  });

  const getAggregateGroupNodes = (filters, aggregates, aggregateGroup) => _.flatten(_.map(filters[aggregateGroup], (group) => group.nodes || _.find(aggregates[aggregateGroup], { id: group.id }).nodes));

  class DataPackager {
    constructor (dates, grains, metricFamily, configuration, enabled, $q) {
      this.dates = dates;
      this.grains = grains;
      // Each row has a reference to a metric. Metrics are manipulated in the packager and should not
      // affect the metrics defined in the controller
      this.metricFamily = _.cloneDeep(metricFamily);
      this.configuration = configuration;
      this.enabled = enabled;
      this.$q = $q;
    }

    transform (promise) {
      return promise
        .then(this.filter.bind(this))
        .then(this.resize.bind(this))
        .then(this.setDataType.bind(this))
        .then(this.flatten.bind(this))
        .then(this.setRowMetadata.bind(this))
        .then(this.sort.bind(this))
        .then(this.emit.bind(this));
    }

    filter (datasets) {
      // The metric family API returns the entire dataset as a result
      // we prune down the dataset to what was requested in the filters
      const filterRecords = (records, grainKey) =>
        _.remove(records, (record) =>
          !_.some(this.configuration.filters[grainKey], (filter) =>
            Comparison.areIdentityEqual(Name.ofIdentity(filter), Name.ofIdentity(record.granularity[grainKey]))));
      datasets.forEach((dataset) => {
        if (dataset.metricFamilyId === SUMMARY_METRIC_FAMILY) {
          return;
        }
        filterRecords(dataset.records, 'node');
        filterRecords(dataset.aggregateRecords, this.configuration.filters.activeGroup);
        dataset.records.push(...dataset.aggregateRecords);
      });
      return datasets;
    }

    resize (datasets) {
      const resize = (dateRange, primaryPlan, comparisonPlan) => {
        if (_.isNil(primaryPlan) || _.isNil(comparisonPlan)) {
          return;
        }
        const result = DateUtils.dateArrayComparator(primaryPlan.periodStartDates, comparisonPlan.periodStartDates),
              options = {
                append: result.postfix.count,
                prepend: result.prefix.count,
                property: 'values'
              };
        comparisonPlan.records.forEach((record) => Grid.resize(record.metrics, options));
      };
      // resize the comparison plan to be the same size as the primary plan
      resize(this.dates, datasets[0], datasets[1]);
      return datasets;
    }

    setDataType (datasets) {
      const setDataType = (dataset, forecastDataType) => {
        if (_.isNil(dataset)) {
          return;
        }
        dataset.records.forEach((grid) => {
          grid.metrics.forEach((metric) => {
            if (metric.type === 'Actual') {
              metric.dataType = 'actual';
            }
            if (metric.type === 'Forecast') {
              metric.dataType = forecastDataType;
            }
          });
        });
      };
      setDataType(datasets[0], 'primary');
      setDataType(datasets[1], 'comparison');
      return datasets;
    }

    flatten (datasets) {
      const sets = {};
      // Flatten each dataset into individual grids
      _.forEach(datasets, (dataset) => {
        dataset.records.forEach((record) => {
          const gridKey = gridKeyFn(record.granularity, this.configuration.filters.activeGroup);
          sets[gridKey] = _.defaultTo(sets[gridKey], []).concat(record.metrics);
        });
      });
      return sets;
    }

    setRowMetadata (datasets) {
      // All grids will hold references to metrics in Metric family
      const setMetricReference = (list, entity) => {
        list.forEach((row) => {
          row.metric = _.find(this.metricFamily.metrics, (familyMetric) => Comparison.areMetricsEqual(familyMetric, row));
          row.granularity = {
            entity: entity,
            metric: Name.ofMetric(row.metric)
          };
        });
      };
      _.forEach(datasets, (dataset, entity) => setMetricReference(dataset, entity));
      return datasets;
    }

    sort (datasets) {
      _.forEach(datasets, (dataset, title) => {
        datasets[title] = Comparison.sort(dataset, ['actual', 'primary', 'comparison'], this.grains.values(), this.metricFamily.metrics);
      });
      return datasets;
    }

    emit (datasets) {
      return {
        grids: _.transform(
          datasets,
          (accumulator, dataset, title) => {
            accumulator[title] = DataPackage.create({
              dates: this.dates,
              enabled: this.enabled,
              granularity: this.configuration.granularity,
              // Compute vertical divider position as per TT: https://tt.amazon.com/0151084868
              historicalDividerIndex: DateUtils.difference(_.head(this.configuration.primary.plans).lastUpdatedAt, _.head(this.dates), Enums.TimeUnit.DAY),
              records: dataset,
              title: title,
              type: Enums.DataPackage.PackageType.NETWORK,
              viewGrainFilter: Enums.GrainFilter.IS_METRIC_GRAIN
            }, this.$q);
          }, {}),
        metricFamily: this.metricFamily
      };
    }
  }

  class NetworkPackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['metricsService', '$q'];
    }

    constructor (metricsService, $q) {
      super($q);
      this.metricsService = metricsService;
    }


    /* Combines grids for multiple metric families at the network levels
     *
     * @param dataPackages the list of network data packages to combine
     * @param filters the set of filters
     * @return a single grid that is the combination of grids
     */
    _combine (dataPackages, filters, aggregates, enabled) {
      return this.$q.all(dataPackages).then((dataPackages) => {
        _.remove(dataPackages, (dataPackage) => _.isEmpty(dataPackage.grids));
        const combinedGrids = {};
        const summaryPackage = _.remove(dataPackages, (pkg) => pkg.metricFamily.id === SUMMARY_METRIC_FAMILY);
        if (!_.isEmpty(summaryPackage)) {
          combinedGrids[SUMMARY_METRIC_FAMILY] = _.get(_.head(summaryPackage).grids, SUMMARY_METRIC_FAMILY);
        }
        dataPackages = _.sortBy(dataPackages, (dataPackage) => dataPackage.metricFamily.displayOrder);
        const order = gridOrdering(aggregates, enabled, filters.activeGroup);
        dataPackages.forEach(
          (pkg) => order.forEach(
            (key) => {
              const nextValue = _.get(pkg.grids, key);
              if (!_.isNil(nextValue)) {
                combinedGrids[key] = combineRecords(combinedGrids[key], nextValue, enabled);
              }
            }
          )
        );
        return _.values(combinedGrids);
      });
    }

    _getMetricFamily (metricFamily, startDate, endDate, aggregates, filters, granularity, lastUpdateByTimeInISO, plans,
      aggregateGroup) {
      const body = {
        aggregateGroupBy: _.isEmpty(filters[aggregateGroup]) ? [] : [aggregateGroup],
        aggregateOnly: _.isEmpty(filters.node),
        asOfTime: lastUpdateByTimeInISO,
        filters: {
          node: _.uniq(_.concat(filters.node, getAggregateGroupNodes(filters, aggregates, aggregateGroup)))
        },
        groupBy: ['node'],
        mappings: getGroupMappings(filters, aggregates, aggregateGroup),
        periodEndDate: endDate,
        periodStartDate: startDate,
        planMetadata: plans[metricFamily.id].map((plan) => plan.source)
      };
      let planGranularity = granularity.plan;
      if (metricFamily.id === SUMMARY_METRIC_FAMILY) {
        delete body.aggregateGroupBy;
        delete body.aggregateOnly;
        delete body.mappings;
        body.filters = {};
        body.groupBy = ['metric'];
        planGranularity = 'PRODUCTLINE';
      }
      return this.metricsService
        .metricFamily(metricFamily, this.metricsService.NETWORK_VIEWER_NAMESPACE, planGranularity, granularity.time, body);
    }

    _fetchMetrics (dates, metricFamily, configuration, enabled) {
      const requests = [];

      let metricsToRequest = pruneMetricFamily(metricFamily, enabled, 'primary');

      // Only request a metric family if it has a corresponding planMetadata object
      if (!_.isEmpty(configuration.primary.planMap[metricsToRequest.id])) {
        requests.push(
          this._getMetricFamily(
            metricsToRequest,
            _.head(dates),
            _.last(dates),
            configuration.aggregates,
            configuration.filters,
            configuration.granularity,
            configuration.primary.lastUpdateByTimeInISO,
            configuration.primary.planMap,
            configuration.filters.activeGroup
          )
        );
      }

      if (enabled.comparison) {
        metricsToRequest = pruneMetricFamily(metricFamily, enabled, 'comparison');
        // Only request a metric family if it has a corresponding planMetadata object
        if (!_.isEmpty(configuration.comparison.planMap[metricsToRequest.id])) {
          requests.push(
            this._getMetricFamily(
              metricsToRequest,
              _.head(configuration.comparison.plans).startDate,
              _.head(configuration.comparison.plans).endDate,
              configuration.aggregates,
              configuration.filters,
              configuration.granularity,
              configuration.comparison.lastUpdateByTimeInISO,
              configuration.comparison.planMap,
              configuration.filters.activeGroup
            )
          );
        }
      }
      return this.$q.all(requests);
    }

    /**
     * Combines metric requests for the Amazon Network, Node Groups, and Nodes
     *
     * @dates Array the range of dates being supported
     * @metricFamilies Array the list of metric families
     * @configuration is a hash containing request metadata:
     *   @primary Object:
     *     @plan Object the primay plan that forecast metrics should be requested for
     *   @granularity Object the granularity that should be applied to each request
     *   @filters Object the filters that should be applied to each request
     * @enabled
     *   @actuals Boolean a flag indicating whether requesting actuals is required
     *   @comparison Boolean a flag indicating whether requesting comparison plan data is required
     *
     * @returns Hash mapping the grid title to an object in the form:
     *   {
     *     dates: Array
     *     grains: Object
     *     metricFamily: Object,
     *     enabled: Object,
     *     grid: Promise --> Object
     *       Array of data rows
     *   }
     */
    collect (dates, metricFamilies, configuration, enabled) {
      const dataPackages = _.map(metricFamilies, (metricFamily) => {
        const transformer = new DataPackager(dates, configuration.granularity.grains, metricFamily, configuration, enabled, this.$q);
        return transformer.transform(this._fetchMetrics(dates, metricFamily, configuration, enabled));
      });
      return this._combine(dataPackages, configuration.filters, configuration.aggregates, enabled).then((dataPackageList) => {
        if (!enabled.metricView) {
          return dataPackageList;
        }
        const map = new Map();
        dataPackageList.forEach((pkg) => {
          pkg.records.forEach((record) => {
            // Remove bold styling for all backlog metrics
            record.metric.isBacklogMetric = false;
            const transposedPackage = map.get(record.metric.id) || _.defaults({ records: [], title: '' }, pkg);
            transposedPackage.records.push(record);
            if (_.isEmpty(transposedPackage.title) || record.metric.type === 'Actual') {
              transposedPackage.title = Name.ofMetric(record.metric);
            }
            record.isGroup = enabled.drillDown &&
                _.some(_.concat(configuration.aggregates[configuration.filters.activeGroup]),
                  (group) => group.name === record.granularity.entity);
            transposedPackage.granularity.grains = Granularities.create().addMetricGrain().addEntityGrain();
            transposedPackage.viewGrainFilter = Enums.GrainFilter.IS_ENTITY_GRAIN;
            map.set(record.metric.id, transposedPackage);
          });
        });
        return [...map.values()];
      });
    }
  }

  angular.module('application.services').service('networkPackager', NetworkPackagerService);
})();
