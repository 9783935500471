/* globals AbstractItemSelectorComponent, Enums */
(function () {
  'use strict';

  const getOperationalPlanning = (type) => type === 'NetworkViewer' ? Enums.Plan.PlanType.DAILY_PLAN : type;

  class WarehouseRegionItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', '$q', 'warehouses'];
    }

    constructor ($element, $q, warehouses) {
      super($element);
      this.$q = $q;
      this.warehouses = warehouses;
    }

    _loadData () {
      // If the configuration is not defined then do no further processing
      if (_.isEmpty(this.configuration)) {
        this.itemSelectorInterface = undefined;
        return;
      }

      this.itemSelectorInterface = {
        items: () => {
          const groupOptions = { asOfDate: this.configuration.date };
          const warehouseOptions = _.assign({ operationalCategory: getOperationalPlanning(this.configuration.type) }, groupOptions);
          return this._regionsWithWarehouses(groupOptions, warehouseOptions);
        },
        preferences: {
          hive: this.configuration.type,
          key: this.metricType
        }
      };
    }

    _getRegionNodes (region, warehouses) {
      if (region === undefined) {
        return;
      }
      return _.map(_.filter(warehouses, (warehouse) => warehouse.nodeRegion === region), 'warehouseId');
    }

    _regionsWithWarehouses (groupOptions, warehouseOptions) {
      return this.$q.resolve(this.warehouses.warehouse().list(warehouseOptions)).then((warehouses) => {
        const regions = _.uniq(_.map(warehouses, Enums.GrainIdentity.nodeRegion))
          .map((region) => ({
            id: region,
            name: region,
            nodes: this._getRegionNodes(region, warehouses)
          }))
          .filter((region) => !_.isEmpty(region.nodes));
        const root = {
          id: 'All',
          items: regions,
          name: 'All'
        };
        return [root];
      });
    }

    $onInit () {
      super.$onInit();
      this.disableSearch = true;
    }
  }

  angular.module('application.components')
    .component('warehouseRegionItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        metricType: '<'
      }),
      controller: WarehouseRegionItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
