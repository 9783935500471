/* globals Configuration, Enums, Ready, Warehouse */
(function () {
  'use strict';

  class WarehouseListController {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', '$scope', '$uibModal', 'warehouses'];
    }

    constructor (alerts, $authentication, overlay, $scope, $uibModal, warehouses) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.$scope = $scope;
      this.$uibModal = $uibModal;
      this.warehouses = warehouses;
      this.hasRegions = Configuration.activation.isAvailable('GroupBy_nodeRegion');

      this.warehouseList = [];
      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.warehouseList),
        isLoadingFn: () => this.loading
      });
    }

    canCreate () {
      return !Configuration.scopes.current().isComposite() && this.$authentication.hasAdministratorAccess();
    }

    loadData () {
      this.loading = true;
      this.warehouseList.length = 0;
      // Clear any user input fields that should be cleared with new data being loaded
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.warehouses
        .warehouse()
        .list()
        .then((warehouses) => this.warehouseList = warehouses)
        .finally(() => this.loading = false);
    }

    openWarehouseModal ($event, warehouse = Warehouse.template()) {
      if (!_.isNil($event)) {
        $event.stopPropagation();
      }
      this.$uibModal
        .open({
          component: 'warehouseModal',
          resolve: {
            warehouse: () => warehouse,
            warehouseList: () => this.warehouseList
          },
          size: Enums.ModalSize.EXTRA_LARGE
        })
        .result.then((modalResponse) => {
          this[modalResponse.mode](modalResponse.warehouse);
        })
        .catch(_.noop);
    }

    delete (warehouse) {
      this.overlay.show(`Removing warehouse: ${warehouse.warehouseId}`);
      this.warehouses
        .warehouse(warehouse)
        .delete()
        .then((response) => this.alerts.success(`Successfully removed warehouse: ${response.warehouseId}`))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to remove warehouse: ${warehouse.warehouseId}` }))
        .finally(() => this.overlay.hide());
    }

    save (warehouse) {
      this.overlay.show(`Saving warehouse: ${warehouse.warehouseId}`);
      this.warehouses
        .warehouse(warehouse)
        .upsert()
        .then((response) => this.alerts.success(`Successfully saved warehouse: ${response.warehouseId}`))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to save warehouse: ${warehouse.warehouseId}` }))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      this.warehouses.$onWarehouseChange(this.loadData.bind(this));
      this.loadData();
    }
  }

  /**
   * Component to display list of warehouses
   * @name application.components.warehouse-list
   * @example
   * <warehouse-list/>
   */
  angular.module('application.components')
    .component('warehouseList', {
      controller: WarehouseListController,
      templateUrl: 'templates/components/warehouse-list.component.html'
    })
    .filter('warehouseListFilter', function () {
      return (input, predicate) => {
        let whList = _.clone(input);
        if (!_.isEmpty(predicate)) {
          if (!_.isEmpty(predicate.warehouseId)) {
            whList = _.filter(whList, (wh) => wh.warehouseId.toLowerCase().includes(predicate.warehouseId.toLowerCase()));
          }
          if (!_.isEmpty(predicate.scope)) {
            whList = _.filter(whList, (wh) => wh.scope.toLowerCase().includes(predicate.scope.toLowerCase()));
          }
          if (!_.isEmpty(predicate.enabledPlans)) {
            whList = _.filter(whList, (wh) => JSON.stringify(wh.enabledPlans).toLowerCase().includes(predicate.enabledPlans.toLowerCase()));
          }
          if (!_.isEmpty(predicate.groups)) {
            whList = _.filter(whList, (wh) => JSON.stringify(wh.groups).toLowerCase().includes(predicate.groups.toLowerCase()));
          }
          if (!_.isEmpty(predicate.nodeRegion) && Configuration.activation.isAvailable('GroupBy_nodeRegion')) {
            whList = _.filter(whList, (wh) => (wh.nodeRegion !== undefined) && (wh.nodeRegion.toLowerCase().includes(predicate.nodeRegion.toLowerCase())));
          }
        }
        return whList;
      };
    });
})();
