/* globals Enums */
(function () {
  'use strict';

  class WarehouseGroupListController {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', '$scope', 'warehouses'];
    }

    constructor (alerts, $authentication, overlay, $scope, warehouses) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.$scope = $scope;
      this.warehouses = warehouses;

      this.groupList = [];
      this.allGroupsList = [];
    }

    loadData () {
      // Clear any user input fields that should be cleared when new data is loaded
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.warehouses.groups().then((groups) => this.groupList = groups);
      this.warehouses.allGroups().then((groups) => this.allGroupsList = groups);
    }

    /**
     * Calls warehouse registry service to add a unique group to the list
     */
    addWarehouseGroup (groupId) {
      // Split the string by spaces or repeated spaces
      const sanitizedId = _.words(groupId, /[^, ]+/g).join(' ');
      // Check to make sure the groupName doesn't already exist in this scope
      if (!_.find(this.groupList, (group) => group.groupId.toUpperCase() === sanitizedId.toUpperCase())) {
        this.alerts.info(`Creating new warehouse group: ${sanitizedId}`);
        this.warehouses.addGroup(sanitizedId)
          .then(() => this.alerts.success(`Successfully added new warehouse group: ${sanitizedId}`))
          .catch((error) => this.alerts.danger({ details: error, message: `Unable to add new warehouse group: ${sanitizedId}` }));
      }
    }

    /**
     * Calls warehouse registry service to delete a group from the current scope
     */
    deleteWarehouseGroup (groupId) {
      this.overlay.show(`Removing warehouse group: ${groupId}`);
      this.warehouses
        .deleteGroup(groupId)
        .then(() => this.alerts.success(`Successfully removed warehouse group: ${groupId}`))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to remove warehouse group: ${groupId}` }))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      this.warehouses.$onGroupChange(() => this.loadData());
      this.loadData();
    }
  }

  /**
   * Component to provide warehouse group logic to the generic group list
   * @name application.components.warehouse-group-list
   * @example
   * <warehouse-group-list/>
   */
  angular.module('application.components')
    .component('warehouseGroupList', {
      controller: WarehouseGroupListController,
      templateUrl: 'templates/components/warehouse-group-list.component.html'
    });
})();
